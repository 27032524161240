// Config values
$platform-name: 'westbankredesign2019';
$assets-path: '../assets/';


// Colours
$_red: #cd1719;
$_blue: #00aeef;
$_pink: #e6007e;
$_green: #afca0b;
$_yellow: #ffcc00;
$_purple: #a6519a;
$_orange: #ed7004;
$_burgundy: #7b0041;
$_dark-blue: #283583;

$brand-primary: $_red;
$brand-secondary: #F0F0F0;
$donate-colour: $_red;


// Create greyscale
$black: #000;
$grey-dark: #1E1E1E;
$grey: #343434;
$grey-light: #646464;
$grey-lighter: #ECECEC;
$grey-lightest: #F7F7F7;
$white: #FFF;


// Layout
$container-max-width: 1240px;
$gap-width: 20px;
$site-bleed: 10px;

$border-radius: 0;
$border-colour: $grey-lighter;
$box-shadow: none;
// $box-shadow: 1px 1px 5px 1px rgba(0,0,0,0.25);

// Breakpoints
$breakpoints: (
 xs: 0,
 sm: 576px,
 md: 768px,
 lg: 1080px,
 xl: 1200px
);


// Spacers
$spacer: 1rem;
$spacer-y: $spacer;
$spacer-x: $spacer;


// Misc
$body-bg: $grey-lightest;
$body-colour: $grey-dark;
$profile-pics-enabled: true;
$border-width: 6px;
$date-ordinals-enabled: true;


// Transitions
$transition-duration: 0.3s;
$transition-duration-slow: 0.3s;
$transition-curve: ease;


// Logo
$logo: "westbank-logo.png";
$logo-width: 150px;
$logo-height: 130px;
$logo-absolute: true;
$logo-breakpoint: map-get($breakpoints, md);
$logo-mobile: $logo;
$logo-mobile-width: $logo-width * 0.45;
$logo-mobile-height: $logo-height * 0.45;

// Typography
$font-family-base: 'century-gothic', sans-serif;
$font-size-base: 1rem;
$font-size-large: 1.3em;
$font-size-small: 0.85em;

$font-weight-normal: 400;
$font-weight-bold: 700;
$font-weight-base: $font-weight-normal;
$line-height-base: 1.45;

$font-size-h1: 2.5em;
$font-size-h2: 2em;
$font-size-h3: 1.75em;
$font-size-h4: 1.5em;
$font-size-h5: 1.25em;
$font-size-h6: 1.1em;

$headings-margin-top: 0.75em;
$headings-margin-bottom: 0.75em;
$headings-font-family: inherit;
$headings-font-weight: $font-weight-bold;
$headings-line-height: 1.1;
$headings-colour: inherit;
$headings-text-transform: none;

$hr-border-colour: $border-colour;
$hr-border-width: 2px;

// Links
$link-colour: $brand-primary;
$link-decoration: none;
$link-hover-decoration: none;
$link-hover-style: darken;

// Icon font
$icon-font-family: "FontAwesome";

// Buttons
$btn-background-colour: transparent;
$btn-text-colour: $brand-primary;
$btn-border-radius: 0;
$btn-border-width: 3px;
$btn-border-colour: $brand-primary;
$btn-box-shadow: none;
$btn-padding-x: 1.2em;
$btn-padding-y: 0.6em;
$btn-font-family: $headings-font-family;
$btn-font-weight: $headings-font-weight;
$btn-font-size: inherit;
$btn-text-transform: uppercase;
$btn-line-height: 1.25;
$btn-hover-style: darken; // darken or lighten the button on hover
$btn-transition-duration: $transition-duration;

// Donate button
$donate-btn-background-colour: $donate-colour;
$donate-btn-colour: text-contrast($donate-colour);

// Social icons

$social-icons-background-colour: transparent;
$social-icons-colour: $grey-light;
$social-icons-hover-colour: $brand-primary;
$social-icons-width: 35px;
$social-icons-height: 35px;
$social-icons-font-size: 1.35rem;
$social-icons-gap: 5px;
$social-icons-border-radius: 0;
$social-icons-box-shadow: none;

// Header specific
$social-icons-header-background-colour: transparent;
$social-icons-header-colour: $social-icons-colour; // A colour, or 'brand'
$social-icons-header-hover-colour: $social-icons-hover-colour; // A colour, or 'brand'

// Footer specific social icons
$social-icons-footer-background-colour: transparent;
$social-icons-footer-colour: $white;
$social-icons-footer-hover-colour: $_red;

// Share this page
$share-enabled: true;
$share-text-align: center;
$share-label-font-family: $font-family-base;
$share-label-font-size: $font-size-h4;
$share-label-font-colour: $body-colour;

// Form elements
$input-padding-y: 0.375rem;
$input-padding-x: 0.75rem;
$input-line-height: 1.25;
$input-colour: $body-colour;
$input-border-colour: #ced4da;
$input-border: 1px solid $input-border-colour;
$input-border-radius: $border-radius;
$input-placeholder-colour: $grey-light;

// Field groups
$field-group-label-width: 280px;

// Tables
$table-cell-padding: 0.75rem;

// Card
$card-gap-width: $gap-width;
$card-border-radius: $border-radius;
$card-border: none;
$card-box-shadow: 0 3px 0 0 $_red;
$card-text-align: left;
$card-image-on: true;
$card-image-opacity: 1;
$card-image-background-colour: rgba($brand-primary, 0.85);
$card-image-padding: 0;
$card-image-border-radius: 0;
$card-details-padding: $spacer*0.75 $spacer $spacer;
$card-details-background-colour: $white;
$card-details-background-gradient: none;
$card-heading-font-family: $headings-font-family;
$card-heading-text-transform: $headings-text-transform;
$card-heading-colour: $body-colour;
$card-heading-font-size: 1.625em;
$card-heading-margin-bottom: $spacer * 0.75;
$card-summary-enabled: true;
$card-summary-colour: text-contrast($card-details-background-colour);
$card-summary-font-size: $font-size-base;
$card-footer-on: false;
$card-footer-background-colour: rgba($black, 0.075);
$card-footer-background-gradient: none;
$card-footer-colour: $body-colour;
$card-footer-padding: .5rem $spacer;
$card-min-width: 280px;

// Card basic
$card-basic-image-position: above;

// Card text overlay
$card-text-overlay-show-summary-on-hover: true;
$card-text-overlay-summary-always-on: false;
$card-text-overlay-details-max-width: 100%;
$card-text-overlay-details-position-y: bottom;
$card-text-overlay-details-position-x: left;

// Card text over
$card-text-over-image-vertical-text-position: center;
$card-text-over-image-darken-image-amount: 0.5;
$card-text-over-image-tint-colour: $black;
$card-text-over-image-show-summary-on-hover: true;

// Card side by side
$card-side-by-side-image-position: left;
$card-side-by-side-image-width: 50%;
$card-side-by-side-grow-image: true;
$card-side-by-side-vertical-text-position: top;
$card-side-by-side-breakpoint: map-get($breakpoints, sm);

// Card hover state
$card-hover-border-colour: $border-colour;
$card-hover-box-shadow: 0 3px 0 0 $_red;
$card-hover-image-opacity: 0.4;
$card-hover-image-scale: 1;
$card-hover-details-background-colour: $card-details-background-colour;
$card-hover-details-background-gradient: $card-details-background-gradient;
$card-hover-heading-colour: $_red;
$card-hover-summary-colour: text-contrast($card-hover-details-background-colour);

// Event cards
$event-card-floating-date: false;
$event-card-floating-date-background-colour: rgba($grey-lightest, 0.9);
$event-card-floating-date-colour: $body-colour;
$event-card-floating-date-border-radius: 0;
$event-card-floating-date-margin-y: $spacer * 0.25;
$event-card-floating-date-margin-x: $spacer * 0.25;


// Menu admin
$menu-admin-enabled: true;
$menu-admin-max-width: 100%;
$menu-admin-background-colour: transparent;
$menu-admin-contents-max-width: 1220px;
$menu-admin-font-size: $font-size-small;
$menu-admin-align-items: right;
$menu-admin-link-colour: $body-colour;
//$menu-admin-absolute: true;
$menu-admin-login-link-enabled: true;
$menu-admin-manager-link-enabled: true;
$menu-admin-my-details-link-enabled: false;
$menu-admin-logout-link-enabled: true;
$menu-admin-to-nav-breakpoint: map-get($breakpoints, lg);


// Header
$header-background-colour: $grey-lightest;
$header-box-shadow: none;


// Header content
$header-content-max-width: 1220px;
$header-content-margin-top: 0;
$header-content-margin-bottom: 0;
$header-content-padding-top: $spacer * 1.5;
$header-content-padding-bottom: $spacer;
$header-content-padding-left: $site-bleed;
$header-content-padding-right: $site-bleed;


// Tagline
$tagline-enabled: false;


// Main call to action
$cta-btn-background-colour: $btn-background-colour;
$cta-btn-colour: $_blue;
$cta-btn-gap: 10px;
$cta-btn-breakpoint: map-get($breakpoints, sm);
$cta-donate-on-appeal: false;


// Search
$header-search-enabled: false;
$header-search-margin-right: 5px;
$header-search-breakpoint: map-get($breakpoints, lg);

// Search - input
$header-search-input-max-width: 120px;
$header-search-input-height: initial;
$header-search-input-margin-right: 8px;
$header-search-input-padding: $input-padding-y $input-padding-x;
$header-search-input-placeholder-colour: $grey-light;
$header-search-input-border-radius: $border-radius;
$header-search-input-border-colour: $grey-light;
$header-search-input-border-width: 0 0 1px 0;
$header-search-input-background-colour: transparent;
$header-search-input-colour: $grey-light;
$header-search-input-focus-background-colour: $white;
$header-search-input-focus-colour: $grey-light;

// Search - button
$header-search-button-width: 35px;
$header-search-button-height: 35px;
$header-search-button-background-colour: transparent;
$header-search-button-icon-font-size: $social-icons-font-size;
$header-search-button-icon-colour: $input-placeholder-colour;
$header-search-button-border-radius: $border-radius;

// Search when in nav
$nav-search-enabled: $header-search-enabled;
$nav-search-input-margin-right: 5px;
$nav-search-input-padding: $input-padding-y $input-padding-x;
$nav-search-input-placeholder-colour: $white;
$nav-search-input-border-colour:  $white;
$nav-search-input-border-width: 0 0 2px 0;
$nav-search-input-background-colour: transparent;
$nav-search-button-background-colour: transparent;
$nav-search-button-icon-colour: $white;


// Social icons
$header-social-icons-margin-right: 15px;
$header-social-icons-margin-bottom: 0px;
$header-social-icons-above-cta-buttons: false;
$header-social-icons-breakpoint: map-get($breakpoints, lg);


// Sticky header
$header-sticky-enabled: true;
$header-sticky-background-colour: $header-background-colour;
$header-sticky-box-shadow: none;
$header-sticky-logo: $logo;
$header-sticky-logo-width: $logo-width * 0.45;
$header-sticky-logo-height: $logo-height * 0.45;
$header-sticky-menu-admin-enabled: false;
$header-sticky-search-enabled: false;
$header-sticky-social-icons-enabled: false;
$header-sticky-cta-btns-enabled: false;
$header-sticky-header-content-margin-top: 0;
$header-sticky-header-content-margin-bottom: 0;
$header-sticky-nav-margin-bottom: 0;


// Navigation
$nav-type: normal;
$nav-font-family: $font-family-base;
$nav-breakpoint: map-get($breakpoints, lg);
// $nav-background-colour: $_red;
// $nav-background-colour: $grey-lightest;
// $nav-background-colour: $grey-dark;
$nav-background-colour: $white;
$nav-text-transform: none;


// Nav normal
// $nav-normal-max-width: 100%;
$nav-normal-max-width: $container-max-width;
$nav-normal-margin-top: 0;
$nav-normal-margin-bottom: 0;
$nav-normal-contents-max-width: $container-max-width;
$nav-normal-align-items: right;
$nav-normal-mobile-background-colour: $grey-light;
$nav-normal-mobile-top-level-item-colour: text-contrast($nav-normal-mobile-background-colour);
$nav-normal-mobile-submenu-background-colour: rgba($grey, 0.55);
$nav-normal-mobile-submenu-item-colour: text-contrast($nav-normal-mobile-background-colour);
$nav-normal-mobile-chevrons-enabled: true;
$nav-normal-mobile-chevrons-colour: $white;


// Top level items
$nav-top-level-item-padding: 12.5px 20px;
$nav-top-level-item-colour: $body-colour;
$nav-top-level-item-font-family: $nav-font-family;
$nav-top-level-item-font-size: $font-size-base;
$nav-top-level-item-font-weight: $font-weight-base;
$nav-top-level-item-background-colour: transparent;
$nav-top-level-item-hover-colour: $body-colour;
$nav-top-level-item-hover-background-colour: rgba($white, 0.075);


// Submenus
$nav-submenu-width: 220px;
$nav-submenu-background-colour: #3A3A3A;
$nav-submenu-background-colour: $_dark-blue;
$nav-submenu-box-shadow: none;
$nav-submenu-border-radius: 0;
$nav-submenu-item-padding: 10px 25px;
$nav-submenu-item-colour: $white;
$nav-submenu-item-font-family: $nav-font-family;
$nav-submenu-item-font-size: $font-size-base;
$nav-submenu-item-font-weight: $font-weight-normal;
$nav-submenu-item-hover-colour: $white;
$nav-submenu-item-hover-background-colour: rgba($white, 0.1);
$nav-submenu-chevrons-enabled: true;
$nav-submenu-chevrons-colour: $white;


// Burger button
$burger-btn-background-colour: transparent;
$burger-btn-text-colour: $body-colour;
$burger-btn-text-transform: $btn-text-transform;
$burger-btn-icon-breakpoint: null;
$burger-btn-bar-breakpoint: null;


// Carousel
// $carousel-max-width: 100%;
$carousel-max-width: $container-max-width;
$carousel-border-radius: 0;
$carousel-margin-top: 0;
$carousel-margin-bottom: 0;
$carousel-contents-max-width: $container-max-width;
$carousel-breakpoint: map-get($breakpoints, lg);
$carousel-details-style: adjacent;
// $carousel-details-position-y: bottom;
// $carousel-details-position-x: center;
$carousel-details-position-y: center;
$carousel-details-position-x: left;
$carousel-details-background-colour: $white;
// $carousel-details-background-colour: transparent;
// $carousel-details-box-shadow: 0 4px 0 0 $_red;
// $carousel-details-max-width: 720px;
$carousel-details-max-width: 420px;
$carousel-details-min-height: auto;
$carousel-details-padding: 0.5rem 1.5rem;
$carousel-details-margin-y: 0;
$carousel-details-margin-x: 0;
$carousel-details-border-radius: 0;
$carousel-details-text-align: left;
$carousel-heading-text-transform: $headings-text-transform;
$carousel-heading-colour: $body-colour;
// $carousel-heading-colour: $white;
$carousel-heading-font-size: 1.85em;
$carousel-summary-enabled: true;
$carousel-summary-colour: $body-colour;
// $carousel-summary-colour: $white;
$carousel-summary-font-size: inherit;
$carousel-read-more-enabled: true;
$carousel-read-more-background-colour: transparent;
$carousel-read-more-border-colour: $body-colour;
$carousel-read-more-colour: $body-colour;
$carousel-read-more-font-size: $carousel-summary-font-size;

// Carousel buttons (dots or tabs)
$carousel-buttons-enabled: false;
$carousel-buttons-type: dots;

// Carousel controls
$carousel-controls-enabled: true;
$carousel-controls-button-size: 35px;
$carousel-controls-button-gap: 1px;
$carousel-controls-button-background-colour: $grey-dark;
$carousel-controls-button-border-radius: $border-radius;
$carousel-controls-button-box-shadow: none;
$carousel-controls-position-y: bottom;
$carousel-controls-position-x: right;
$carousel-controls-margin-y: 0px;
$carousel-controls-margin-x: 0px;
$carousel-controls-icon-colour: $white;
$carousel-controls-icon-font-size: 1.25em;
$carousel-controls-next-icon: "\f054";
$carousel-controls-prev-icon: "\f053";

// Carousel after breakpoint
$carousel-mobile-details-background-colour: $grey-dark;
$carousel-mobile-heading-colour: $white;
$carousel-mobile-summary-colour: $white;


// Home intro
$home-intro-max-width: $container-max-width;
$home-intro-background-colour: $_red;
$home-intro-box-shadow: none;
$home-intro-content-max-width: $container-max-width;
$home-intro-content-align: center;
$home-intro-padding-y: $spacer * 2;
$home-intro-margin-top: $spacer * 2;
$home-intro-margin-bottom: $spacer * 1.5;
$home-intro-text-align: center;
$home-intro-font-size: 2.5em;
$home-intro-font-weight: $headings-font-weight;
$home-intro-line-height: $line-height-base;
$home-intro-colour: $white;


// Home features
$home-features-max-width: $container-max-width;
$home-features-background-colour: $body-bg;
$home-features-padding-top: 0;
$home-features-padding-bottom: 0;
$home-features-cards-entry-animation-prefab: 1;
$home-features-cards-entry-animation-stagger: true;
$home-features-cards-entry-animation-stagger-count: 3;


// Impact stats
$impact-stats-enabled: true;
$impact-stats-max-width: 1220px;
$impact-stats-margin-top: $spacer * 1.5;
$impact-stats-margin-bottom: $spacer * 1.5;
$impact-stats-padding-y: $spacer * 2.5;
$impact-stats-background-colour: $_dark-blue;
$impact-stats-background-image: 'heartShape.png';
$impact-stats-background-image-opacity: 0.075;
$impact-stats-background-image-size: 720px;
$impact-stats-background-image-repeat: no-repeat;
$impact-stats-colour: $white;
$impact-stats-text-align: center;
$impact-stats-content-max-width: $container-max-width;
$impact-stats-breakpoint: map-get( $breakpoints, md);
$impact-stats-overall-container-direction: column;
$impact-stats-direction: row;
$impact-stats-mobile-text-align: $impact-stats-text-align;

// Heading
$impact-stats-heading-enabled: true; //true or false
$impact-stats-heading-font-size: $font-size-h1;
$impact-stats-heading-text-align: $impact-stats-text-align; // left, center, right
$impact-stats-heading-colour: inherit;
$impact-stats-heading-margin-bottom: $spacer * 0.5;

// Individual stat
$impact-stat-min-width: 180px; // Use this to control how many you get in a row
$impact-stat-direction: column; // column, row
$impact-stat-padding: .5rem 0px;
$impact-stat-margin: $gap-width;
$impact-stat-border: 0px solid $border-colour; // Style border here
$impact-stat-border-width: 0 0 0 0; // Set border widths (t-r-b-l) here

// Figure
$impact-stats-figure-colour: $impact-stats-colour;
$impact-stats-figure-font-family: $headings-font-family;
$impact-stats-figure-font-size: 3em;

// Summary
$impact-stats-summary-colour: inherit;
$impact-stats-summary-font-size: $font-size-base;
$impact-stats-summary-font-weight: $font-weight-base;
$impact-stats-summary-margin-top: .25rem;

// Home feeds
//

$home-feeds-max-width: $container-max-width;
$home-feeds-background-colour: $body-bg;
$home-feeds-padding-top: $spacer;
$home-feeds-padding-bottom: $spacer * 1.5;
$home-feeds-cards-entry-animation-prefab: 1;
$home-feeds-cards-entry-animation-stagger: true;
$home-feeds-cards-entry-animation-stagger-count: 3;

// Feeds title
$feeds-title-font-family: $headings-font-family;
$feeds-title-font-size: $font-size-h4;
$feeds-title-colour: $body-colour;
$feeds-title-text-align: left;

// Feed item
$feed-item-read-more-enabled: false;

// Event feed items
$feed-item-event-start-date-enabled: true;
$feed-item-event-location-enabled: false;


// Footer
$footer-boxes-count: 3;
$footer-prefab: 1;
$footer-max-width: 1220px;
$footer-contents-max-width: 1220px;
$footer-margin-y: 0;
$footer-padding-y: $spacer * 4;
$footer-background-colour: $grey-dark;
$footer-background-gradient: none; // none or CSS gradient
$footer-colour: $white;
$footer-link-colour: $white;
$footer-text-align: left;
$footer-font-size: inherit;
$footer-headings-font-size: inherit;

// Footer admin links
$footer-admin-basket-link-enabled: true;
$footer-admin-login-link-enabled: true;
$footer-admin-manager-link-enabled: true;
$footer-admin-my-details-link-enabled: true;
$footer-admin-logout-link-enabled: true;

// Newsletter
$newsletter-background-colour: $_dark-blue;
$newsletter-background-gradient: none; // none or CSS gradient
$newsletter-colour: text-contrast($newsletter-background-colour);
$newsletter-heading-font-family: $headings-font-family;
$newsletter-heading-font-size: $font-size-h2;
$newsletter-heading-text-align: center; // left, center, right
$newsletter-heading-text-transform: $headings-text-transform;
$newsletter-heading-margin-bottom: $spacer * 2;
$newsletter-heading-colour: $newsletter-colour;
$newsletter-input-background-colour: $white;
$newsletter-button-height: 38px;
$newsletter-button-background-colour: transparent;
$newsletter-button-colour: $white;
$newsletter-button-border-colour: $white;
$newsletter-use-placeholders: true;


// Newsletter hero
$newsletter-hero-padding-y: $spacer * 1.5;
$newsletter-hero-contents-max-width: $footer-contents-max-width;


// Context container
$context-container-background-colour: $white;
$context-container-padding: $spacer * 2;
$context-container-border-radius: $border-radius;


// Header Text
$header-text-margin-top: $spacer * 1.5;
$header-text-margin-bottom: 0;


// Sidebar
$sidebar-enabled: false;


// Donation form
$donation-form-layout: columns;
$donation-form-banner-enabled: true;
$donation-form-frequency-tabs: true;

// Donation amounts
$donation-amount-min-width: $card-min-width;
$donation-amount-figure-font-size: $font-size-h2;
$donation-amount-figure-colour: $donate-colour;
$donation-amount-thumbnail-enabled: true;


// Quick giving panel
$quick-giving-type: simple;
$quick-giving-background-colour: $grey-lightest;
$quick-giving-background-gradient: none;
$quick-giving-padding: $context-container-padding;
$quick-giving-border-radius: $context-container-border-radius;
$quick-giving-donation-amount-figure-font-size: $donation-amount-figure-font-size *
  0.75;
$quick-giving-donation-amount-padding: 0.5rem 1rem;
$quick-giving-donation-amount-background-colour: $donate-colour;
$quick-giving-donation-amount-figure-colour: text-contrast(
  $quick-giving-donation-amount-background-colour
);
$quick-giving-donation-amount-border-width: $btn-border-width;
$quick-giving-donation-amount-border-colour: $_red;
$quick-giving-donation-amount-selected-background-colour: $white;
$quick-giving-donation-amount-selected-figure-colour: text-contrast(
  $quick-giving-donation-amount-selected-background-colour
);



// Home quick giving panel
$home-quick-giving-enabled: true;
$home-quick-giving-type: adjacent;
$home-quick-giving-max-width: 1220px;
$home-quick-giving-content-max-width: 1220px;
$home-quick-giving-margin-top: $spacer * 1.5;
$home-quick-giving-margin-bottom: $spacer * 2.5;
$home-quick-giving-padding-y: $spacer * 2.5;
$home-quick-giving-background-colour: $white;
$home-quick-giving-donation-amount-background-colour: $grey-lightest;
$home-quick-giving-donation-amount-figure-colour: $body-colour;
$home-quick-giving-donation-amount-border-width: 3px;
$home-quick-giving-donation-amount-border-colour: $donate-colour;
$home-quick-giving-donation-amount-selected-background-colour: $white;
$home-quick-giving-donation-amount-selected-figure-colour: $_red;
$home-quick-giving-donate-btn-background-colour: $_red;
$home-quick-giving-donate-btn-colour: $white;
$home-quick-giving-donate-btn-border-colour: $btn-border-colour;
$home-quick-giving-donate-btn-font-size: $font-size-h5;
$home-quick-giving-colour: $body-colour;
$home-quick-giving-heading-colour: $body-colour;
$home-quick-giving-heading-font-size: $font-size-h1;

// Adjacent homepage quick giving
$home-quick-giving-type-adjacent-image-side: left;


// Cookie consent
$cookie-consent-position: bottom;
$cookie-consent-background-colour: $footer-background-colour;
$cookie-consent-colour: $white;
$cookie-consent-btn-background-colour: $_red;
$cookie-consent-btn-text-colour: $white;
