//
// Home features
//

// Choose your overall home feature cards layout
.homeFeatures {
  @include cards-layout-prefab (
    $number-of-cards: 6,
    $prefab: 3
  )
}

// Choose your card style for home feature cards
.homeFeatures .homeFeature {
  @include card (
    $card-image-background-colour: $_red,
    $card-box-shadow: 0 3px 0 0 $_red,
    $card-hover-box-shadow: 0 3px 0 0 $_red,
    $card-hover-heading-colour: $_red
  );
  @include card-basic;


  &.homefeaturecategory-red {
    @include card (
      $card-image-background-colour: $_red,
      $card-box-shadow: 0 3px 0 0 $_red,
      $card-hover-box-shadow: 0 3px 0 0 $_red,
      $card-hover-heading-colour: $_red
    );
  }
  &.homefeaturecategory-green {
    @include card (
      $card-image-background-colour: $_green,
      $card-box-shadow: 0 3px 0 0 $_green,
      $card-hover-box-shadow: 0 3px 0 0 $_green,
      $card-hover-heading-colour: $_green
    );
  }
  &.homefeaturecategory-purple {
    @include card (
      $card-image-background-colour: $_purple,
      $card-box-shadow: 0 3px 0 0 $_purple,
      $card-hover-box-shadow: 0 3px 0 0 $_purple,
      $card-hover-heading-colour: $_purple
    );
  }
  &.homefeaturecategory-pink {
    @include card (
      $card-image-background-colour: $_pink,
      $card-box-shadow: 0 3px 0 0 $_pink,
      $card-hover-box-shadow: 0 3px 0 0 $_pink,
      $card-hover-heading-colour: $_pink
    );
  }
  &.homefeaturecategory-orange {
    @include card (
      $card-image-background-colour: $_orange,
      $card-box-shadow: 0 3px 0 0 $_orange,
      $card-hover-box-shadow: 0 3px 0 0 $_orange,
      $card-hover-heading-colour: $_orange
    );
  }
  &.homefeaturecategory-light-blue {
    @include card (
      $card-image-background-colour: $_blue,
      $card-box-shadow: 0 3px 0 0 $_blue,
      $card-hover-box-shadow: 0 3px 0 0 $_blue,
      $card-hover-heading-colour: $_blue
    );
  }
  &.homefeaturecategory-burgundy {
    @include card (
      $card-image-background-colour: $_burgundy,
      $card-box-shadow: 0 3px 0 0 $_burgundy,
      $card-hover-box-shadow: 0 3px 0 0 $_burgundy,
      $card-hover-heading-colour: $_burgundy
    );
  }
}

// Alternatively, you can target specific features rather than styling them all
// If you do this, you will need to target them ALL uniquely, so:
// .homeBox1 {...}, .homeBox2 {...}, .homeBox3 {...} etc.


//
// Home feeds
//

// Choose your overall home feeds layout
@include home-feeds-prefab (
  $number-of-feeds: 2,
  $prefab: 2
);

// Specify a card layout for each individual feed
.homeFeedBox1 .feedList {
  @include cards-layout-prefab (
    $number-of-cards: 3,
    $prefab: 4
  );
  .feedItem {
    @include card (
      $card-image-background-colour: $_pink,
      $card-box-shadow: 0 3px 0 0 $_pink,
      $card-hover-box-shadow: 0 3px 0 0 $_pink,
      $card-hover-heading-colour: $_pink
    );
    @include card-basic;
  }
}

.homeFeedBox2 .feedList {
  @include cards-layout-prefab (
    $number-of-cards: 3,
    $prefab: 12
  );
  .feedItem {
    @include card (
      $card-image-background-colour: $_purple,
      $card-box-shadow: 0 3px 0 0 $_purple,
      $card-hover-box-shadow: 0 3px 0 0 $_purple,
      $card-hover-heading-colour: $_purple
    );
    @include card-basic;
  }

}

// Choose your card style for home feed cards


//
// Listed posts
//

// Choose your card style for listed post cards
// (not products, search result or embedded map item)
.listedPost:not(.listedProduct):not(.listedSearchResult):not(.listedMapItem) {
  @include card;
  @include card-basic;
  &.listedEvent {
    @include card (
      $card-image-background-colour: $_purple,
      $card-hover-box-shadow: 0 3px 0 0 $_purple,
      $card-box-shadow: 0 3px 0 0 $_purple,
      $card-hover-heading-colour: $_purple
    );
    @include card-basic;
  }

  &.module-news {
    @include card (
      $card-image-background-colour: $_pink,
      $card-hover-box-shadow: 0 3px 0 0 $_pink,
      $card-box-shadow: 0 3px 0 0 $_pink,
      $card-hover-heading-colour: $_pink
    );
    @include card-basic;
  }

  &.PostCategory_volunteering-roles {
    @include card (
      $card-image-background-colour: $_green,
      $card-hover-box-shadow: 0 3px 0 0 $_green,
      $card-box-shadow: 0 3px 0 0 $_green,
      $card-hover-heading-colour: $_green
    );
    @include card-basic;
  }

}


//
// Listed products
//

// If these are exactly the same as your regular 'listed posts' above, you can remove the ':not(.listedProduct)' selector above and squash this together into more efficient CSS output
.listedProduct {
  @include card;
  @include card-basic;
}
