// Header - Background
.headerContent {
  background-color: $white;
  @media (max-width: 1080px) {
    border-bottom: 3px solid $_red;
    padding: 0.5rem 10px !important;
    margin-top: 0 !important;
  }
}


// Burger Menu - Button
.menuMainAlt, .menuMainAlt.active {
  border: none;
  color: $body-colour;
  background-color: transparent;
  &:hover {
    background-color: transparent;
    color: lighten($body-colour, 10%);
  }
}

// Logo - kill absolute on smaller screens
@media (max-width: 1080px) {
  .mainLogo {
    position: relative !important;
  }
}

// Header - Border + Layout
// MenuAdmin - Background
@media (min-width: 1081px) {
  .menuMain {
    border-bottom: 3px solid $_red;
    padding-bottom: .5rem;
  }
  .menuAdminContainer #menuAdmin {
    background-color: $white;
  }
}

// Bav - Responsiveness
@media (max-width: 1240px) and (min-width: 1081px) {
  .menuMain {
      max-width: 1240px;
  }
}

// Header sticky - Layout (tweaks needed for logo absolute)
@media (min-width: 1081px) {
  .pageHeader.sticky {
    transition: 300ms all ease !important;
    .headerContent {
      height: 0;
      transition: 300ms all ease !important;
    }
    nav.menuMain {
      margin-top: -25px;
      padding-bottom: .75rem;
      transition: 300ms all ease !important;
    }
    a.mainLogo {
      top: 6px;
      transition: 150ms all ease !important;
    }
  }
}


// Header CTA
.headerContent .cta-button:not(.donate) {
  border: 3px solid $_dark-blue;
  color: $_dark-blue;
  &:hover {
    background-color: $_dark-blue;
    border: 3px solid $_dark-blue;
  }
}


// Carousel - Hover
.carousel { overflow: hidden; }


// Carousel - Glitch fix
.carouselSlide { transition: opacity 0s ease; }


// Carousel - Banner image layout
.carouselSlide img {
  max-height: 496px;
  object-fit: cover;
  @media (max-width: 1240px) {
    max-height: 40vw;
  }
}


// Carousel - Controls
@media (min-width: 1081px) {
  .carouselControls { opacity: 0; }
  .carousel:hover .carouselControls { opacity: 1; }
  .carouselControls a { background-color: $white; }
  .carouselControls a:before { color: $body-colour; }
  .carouselControls a:hover:before { color: $white; }
}


// Carousel - Read More
.carouselSlideReadMore {
  margin-top: 1.5rem;
  @media (max-width: 1080px) {
    border: 3px solid $white;
    color: $white;
  }
}


// Home Stats - Background
.homefeaturecategory-homeboximpactstats:before {
  background-position: 180% 30%;
}


// Home Stats - Layout
.homeImpactWrapper tr td:first-of-type { order: 2; }
.homeImpactWrapper tr td:nth-of-type(2) { order: 1; }
.homeImpactWrapper tr td:last-of-type { order: 3; }


// Home stats - Icons
.homeImpactWrapper img {
  filter: drop-shadow(4px 4px 0px rgba(255, 255, 255, 0));
  max-height: 100px;
  object-fit: contain;
}


// Home stats - Hover
.homefeaturecategory-homeboximpactstats tr:hover img {
  transform: scale(1.1) translateY(-5px);
  filter: drop-shadow(4px 4px 0px rgba(255, 255, 255, 0.05));
}


// Home Stats - Alignment
.homefeaturecategory-homeboximpactstats tbody {
  align-items: flex-start;
}


// Home stats - Mobile
@media (max-width: 1080px) {
.homefeaturecategory-homeboximpactstats {
    margin: 2rem 0;
  }
}


// HomeFeeds - Layout
@media (min-width: 769px) {
  .homeFeedBox1 { flex: 2; }
  .homeFeedBox2 { flex: 1; }
}

.homeFeed .feedsTitle {
  width: calc(100% - 20px);
  border-bottom: 3px solid $body-colour;
  margin: 0 auto .5rem;
  padding: 0 0 5px 0;
}


// Event date and location - Tweak
.listedPost.listedEvent p.associatedStartDate, p.locationDetails {
  font-weight: $font-weight-bold;
  opacity: 1 !important;
  // Dsiplay time on listedEvents
  time span:nth-of-type(2) { display: inline-block !important; }
}
.homeFeedBox2 .feedList .feedItem footer .postFooter>* {
  opacity: 1;
}


// Footer - Max width
.pageFooterWrapper {
  overflow: hidden;
  max-width: 1220px;
}
.footerBox:not([class*="ewsletter"]):not([class*="ogos"]) {
  margin: 2rem 20px 0;
}


// Newsletter - Layout
.footerBox[class*="ewsletter"] .featureForm>div:first-child .formQuestion {
  margin-right: 1rem;
}


// Newsletter - Button hover
.footerBox[class*="ewsletter"] button:hover {
  color: $_red;
}


// Footer - Logos
.footerBox.Footer-Logos {
  position: relative;
  margin: 0;
  padding: 1rem 0;
  background-color: $body-bg;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  img {
    max-width: 260px;
    max-height: 70px;
    margin: 10px 20px;
  }
}


// Header Text
.headerText h1.title {
  border-bottom: 3px solid $body-colour;
  width: calc(100% - 20px) !important;
  max-width: 1220px !important;
  @media (min-width: 1081px) {
    padding: 0;
  }
}


// Quick giving panel
.donationAmount.donationSelected {
  box-shadow: inset 0 0 0 3px $_red !important;
}


// Colours focued set-up
@mixin colour-focused ($colour) {
  .breadcrumbWrapper a {
    color: $colour;
  }
  h2, h3, h4 {
    color: $colour;
  }
  .postContent a:not(.donateBtn) {
    color: $colour;
  }

  blockquote {
    background-color: $colour;
  }

  .postContent a.cta-button,
  a.contactOfficeMapBtn,
  a.addToCalender,
  a.callToActionBtn,
  a.backToTop,
  a.loadMore {
    border: 3px solid $colour;
    &:hover {
      background-color: $colour;
      color: $white;
    }
  }
  a.loadMore,
  a.backToTop {
    color: $colour;
    &:hover { color: $white; }
  }

  .shareList a:hover:before {
    color: $colour;
  }

}

// Colours focued per section / category
body[class*='olunte'], body.PostCategory_green {
  @include colour-focused($colour: $_green);
}
body[class*="onat"], body.PostCategory_red {
  @include colour-focused($colour: $_red);
}
body[class*='ews'], body.PostCategory_pink {
  @include colour-focused($colour: $_pink);
}
body.pages-Events-Category-whats-on, body.eventsBody, body.eventsPostBody, body.PostCategory_purple {
  @include colour-focused($colour: $_purple);
}
body.PostCategory_orange {
  @include colour-focused($colour: $_orange);
}
body.PostCategory_burgundy {
  @include colour-focused($colour: $_burgundy);
}
body.PostCategory_light-blue {
  @include colour-focused($colour: $_blue);
}
body.PostCategory_dark-blue {
  @include colour-focused($colour: $_dark-blue);
}

// Add a oblique lien to cards
.homeFeature, .feedItem, .listedPost {
  overflow: hidden;
  .homeFeatureDetailsWrapper, .feedItemDetailsWrapper, .listedPostText {
    z-index: 1;
    position: relative;
    &:before {
      content: '';
      position: absolute;
      display: block;
      background-color: $white;
      top: 0;
      left: 0;
      width: 110%;
      height: 30px;
      transform: rotate(-1.5deg);
      transform-origin: top left;
      z-index: -1;
    }
  }
}

// Add a oblique lien to Newsletter
.footerBox[class*='ewsletter'] {
  position: relative;
  z-index: 1;
  &:after {
    content: '';
    position: absolute;
    display: block;
    background-color: $newsletter-background-colour;
    bottom: 0;
    left: 0;
    width: 105%;
    height: 50px;
    transform: rotate(-1.5deg);
    transform-origin: bottom right;
    z-index: -1;
  }
}

// Carousel - Video pop-up 
.embed-container.videoPopUpWrapper {
  max-width: 1020px;
}
.carouselSlideTitle.showsVideo:before {
  background-color: $_red;
  color: $white;
}
 .carouselSlideTitle.showsVideo:hover:before {
  background-color: $white;
  color: $_red;  
}
.videoPopupClose { background-color: $white;  }
.videoPopupClose:hover:before { color: $white;  }
@media (min-width: map-get($breakpoints, lg) + 1px) {
  .carousel.hasCaption .carouselSlideTitle.showsVideo:before {
    left: 66.666%;
  }
}

@include appeal-field("help-someone-this-christmas", "christmas");
@include appeal-field("help-someone-this-christmas-2021", "christmas");

body[class*='help-someone-this-christmas'] {
  .pageHeader.sticky { background-color: transparent; }
  .breadcrumbWrapper { display: none; }
  .headerText h1.title { border-bottom: none; padding: 0; }
}

body[class*='appeal-help-someone-this-christmas'] {
  #bodyForm {
    background-position: center -320px;
    @media (max-width: map-get($breakpoints, lg)) {
      background-position: center -180px;
    }
    @media (max-width: map-get($breakpoints, md)) {
      background-position: center -40px;
    }
  }
  .appealActions .button { margin: 0!important; }
}

body[class*='donate-help-someone-this-christmas'] {
  .listingIntro { margin-bottom: 20px; }
}